import React, { useState } from "react";
import {
  Col,
  Row,
  Tabs,
  Input,
  Button,
  List,
  Radio,
  Modal,
  Divider,
  notification,
} from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import SaveLoad, { SaveData } from "./SaveLoad";
import SubmitAttendanceForm from "./SubmitAttendanceForm";

const { TextArea } = Input;

interface AttendanceItem {
  name: string;
  present: boolean | null;
  reason: string;
  remarks: string;
}

const AttendancePage = () => {
  const [setNotification, notificationContext] = notification.useNotification();
  const [importText, setImportText] = useState<string>("");
  const [attendance, setAttendanceList] = useState<AttendanceItem[]>([]);
  const [exportLabel, setExportLabel] = useState("");

  const getAttendance = (name: string): AttendanceItem | null => {
    const item = attendance.find((item) => item.name === name);
    if (item) {
      return item;
    }
    return null;
  };

  const importNameList = (text: string) => {
    const names = text.split("\n");
    const newAttendance = names
      .map((name) => {
        const existingAttendance = getAttendance(name);

        if (existingAttendance !== null) {
          return existingAttendance;
        }

        return {
          name,
          present: null,
          reason: "",
          remarks: "",
        };
      })
      .filter((item) => item.name !== "");
    setAttendanceList(newAttendance);
    saveAttendanceListToLocalStorage(newAttendance);
  };

  const markAttendance = (name: string, present: boolean, reason: string) => {
    const newAttendance = attendance.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          present,
          reason,
        };
      }
      return item;
    });
    setAttendanceList(newAttendance);
    saveAttendanceListToLocalStorage(newAttendance);
  };

  const saveAttendanceListToLocalStorage = (attendance: AttendanceItem[]) => {
    localStorage.setItem("attendance", JSON.stringify(attendance));
  };

  const updateReason = (name: string, reason: string) => {
    const newAttendanceList = attendance.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          reason,
        };
      }
      return item;
    });
    setAttendanceList(newAttendanceList);
    saveAttendanceListToLocalStorage(newAttendanceList);
  };

  const getReason = (name: string) => {
    const item = attendance.find((item) => item.name === name);
    if (item) {
      return item.reason;
    }
    return "";
  };

  const updateRemarks = (name: string, remarks: string) => {
    const newAttendanceList = attendance.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          remarks,
        };
      }
      return item;
    });
    setAttendanceList(newAttendanceList);
    saveAttendanceListToLocalStorage(newAttendanceList);
  };

  const getRemarks = (name: string) => {
    const item = attendance.find((item) => item.name === name);
    if (item) {
      return item.remarks;
    }
    return "";
  };

  // Batch Functions
  const resetPresentToNull = () => {
    const newAttendanceList = attendance.map((item) => {
      if (item.present === true) {
        return {
          ...item,
          present: null,
        };
      } else {
        return item;
      }
    });
    setAttendanceList(newAttendanceList);
    saveAttendanceListToLocalStorage(newAttendanceList);
  };

  const resetAll = () => {
    const newAttendanceList = attendance.map((item) => {
      return {
        ...item,
        present: null,
        reason: "",
        remarks: "",
      };
    });
    setAttendanceList(newAttendanceList);
    saveAttendanceListToLocalStorage(newAttendanceList);
  };

  const markRemainingAsPresent = () => {
    const newAttendanceList = attendance.map((item) => {
      if (item.present === null) {
        return {
          ...item,
          present: true,
        };
      } else {
        return item;
      }
    });
    setAttendanceList(newAttendanceList);
    saveAttendanceListToLocalStorage(newAttendanceList);
  };

  // Update attendance modal
  const [currentEdit, setCurrentAddReason] = useState<AttendanceItem | null>(
    null
  );

  const showModal = (item: AttendanceItem) => {
    setCurrentAddReason(item);
  };

  const handleOk = () => {
    setCurrentAddReason(null);
  };

  const handleCancel = () => {
    setCurrentAddReason(null);
  };

  // Load attendance from local storage
  React.useEffect(() => {
    const attendance = localStorage.getItem("attendance");
    if (attendance) {
      setAttendanceList(JSON.parse(attendance));
      setImportText(
        JSON.parse(attendance)
          .map((item: AttendanceItem) => item.name)
          .join("\n")
      );
    }
    const exportLabelMaybe = localStorage.getItem("attendance-exportLabel");
    if (exportLabelMaybe) {
      setExportLabel(JSON.parse(exportLabelMaybe));
    }
  }, []);

  // Export
  const exportSummary = () => {
    const present = attendance.filter((item) => item.present === true);
    const absent = attendance.filter((item) => item.present === false);
    const total = attendance.length;
    const remarks = attendance
      .filter((item) => item.remarks && item.remarks !== "")
      .map((item) => `- ${item.name}(${item.remarks})`)
      .join("\n");
    const summary = `${exportLabel}\n\nTotal: ${total}\nPresent: ${
      present.length
    }\n\nAbsent(${absent.length}):\n${absent
      .map((item) => `- ${item.name}(${item.reason})`)
      .join("\n")}\n\nRemarks:\n${remarks}`;
    return summary;
  };

  const copyToClipboard = (data: string | void, save: boolean) => {
    if (!data) {
      return;
    }
    navigator.clipboard.writeText(data);
    setNotification["success"]({
      message: "Copied to clipboard",
    });
  };

  // For backward compatability
  interface SaveDataOld {
    label: string;
    attendance: any;
  }

  const loadAttendanceList = (savedItem: SaveData | SaveDataOld) => {
    console.log("loading", savedItem);

    // Check if savedItem is old format
    if ("attendance" in savedItem) {
      savedItem = {
        label: savedItem.label,
        data: savedItem.attendance,
      };
    }
    setAttendanceList(savedItem.data);
    saveAttendanceListToLocalStorage(savedItem.data);
    setExportLabel(savedItem.label);
    localStorage.setItem(
      "attendance-exportLabel",
      JSON.stringify(savedItem.label)
    );
    setImportText(
      savedItem.data.map((item: AttendanceItem) => item.name).join("\n")
    );
  };

  const tabs = [
    {
      label: "Working",
      key: "working",
      children: (
        <>
          <Row>
            <Col span={24}>
              <List
                dataSource={attendance}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.name}
                      description={[item.reason, item.remarks]
                        .filter((item) => item !== "")
                        .join(" - ")}
                    />
                    <Radio.Group
                      onChange={(e) =>
                        markAttendance(item.name, e.target.value, "")
                      }
                      value={item.present}
                      optionType="button"
                    >
                      <Radio value={null}>
                        <QuestionOutlined />
                      </Radio>
                      <Radio value={true}>
                        <CheckOutlined />
                      </Radio>
                      <Radio value={false}>
                        <CloseOutlined />
                      </Radio>
                    </Radio.Group>
                    {/* Button which opens modal to input reason */}
                    <Button type="primary" onClick={() => showModal(item)}>
                      <EditOutlined />
                    </Button>
                  </List.Item>
                )}
              />
              <Modal
                title="Update Attendance"
                open={currentEdit !== null}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                {currentEdit ? (
                  <>
                    {(getAttendance(currentEdit?.name)?.present ||
                      getAttendance(currentEdit?.name)?.reason !== "") && (
                      <>
                        <div>Remarks:</div>
                        <TextArea
                          rows={4}
                          placeholder="Add optional remarks such as early drop off etc."
                          value={getRemarks(currentEdit?.name)}
                          onChange={(e) =>
                            updateRemarks(currentEdit?.name, e.target.value)
                          }
                          allowClear={true}
                        />
                      </>
                    )}
                    {(!getAttendance(currentEdit?.name)?.present ||
                      getAttendance(currentEdit?.name)?.remarks !== "") && (
                      <>
                        <div>Reason:</div>
                        <TextArea
                          rows={4}
                          placeholder="Add reason for being absent."
                          value={getReason(currentEdit?.name)}
                          onChange={(e) =>
                            updateReason(currentEdit?.name, e.target.value)
                          }
                          allowClear={true}
                        />
                      </>
                    )}
                  </>
                ) : null}
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider />
              Attendance:{" "}
              {attendance.filter((item) => item.present === true).length} /{" "}
              {attendance.length}
              <br />
              Unmarked:{" "}
              {attendance.filter((item) => item.present === null).length}
              <br />
              Remarks(
              {
                attendance.filter((item) => item.remarks && item.remarks !== "")
                  .length
              }
              )
              <br />
              {attendance
                .filter((item) => item.remarks && item.remarks !== "")
                .map((item) => `- ${item.name}(${item.remarks})`)
                .join("\n")}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider />
              <Input
                placeholder="label"
                value={exportLabel}
                onChange={(e) => {
                  setExportLabel(e.target.value);
                  localStorage.setItem(
                    "attendance-exportLabel",
                    JSON.stringify(e.target.value)
                  );
                }}
              />
              <Button
                type="primary"
                onClick={() => copyToClipboard(exportSummary(), true)}
              >
                Export Summary
              </Button>
              <SubmitAttendanceForm data={exportSummary()} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider />
              <Button type="primary" onClick={() => markRemainingAsPresent()}>
                Mark Remaining
              </Button>{" "}
              <Button type="primary" onClick={() => resetPresentToNull()}>
                Reset Present
              </Button>{" "}
              <Button type="primary" onClick={() => resetAll()}>
                Reset All
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      label: "Import",
      key: "import",
      children: (
        <>
          <TextArea
            rows={15}
            placeholder="Import"
            value={importText}
            onChange={(e) => setImportText(e.target.value)}
          />
          <Button type="primary" onClick={() => importNameList(importText)}>
            Import
          </Button>
        </>
      ),
    },
    {
      label: "Save/Load",
      key: "save/load",
      children: (
        <SaveLoad
          storageKey="savedAttendance"
          currentData={attendance}
          setFn={loadAttendanceList}
        />
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        {notificationContext}
        <Tabs type="card" items={tabs} />
      </Col>
    </Row>
  );
};

export default AttendancePage;
