import { useState } from "react";
import { Input, Modal, Button, notification } from "antd";

const UploadDataForm = ({
  dataKey,
  data,
}: {
  dataKey: string;
  data: string;
}) => {
  const [password, setPassword] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const [notificationApi, notificationContext] = notification.useNotification();

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = async () => {
    console.log(`submitting: ${data} with key ${dataKey}`);

    try {
      const updateRes = await fetch(
        `https://crm.natlabs.app/.netlify/functions/save-data?key=${dataKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: password,
          },
          body: data,
        }
      );

      if (!updateRes.ok) {
        throw new Error(
          `Error saving data with key ${dataKey}, ${updateRes.status} ${updateRes.statusText}`
        );
      }

      notificationApi.success({
        message: "Success",
        description: "Successfully saved data",
        placement: "topRight",
      });
      setModalOpen(false);
    } catch (err) {
      notificationApi.error({
        message: "Error",
        description: `Error saving data ${err}`,
        placement: "topRight",
      });
      setModalOpen(false);
      return;
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      {notificationContext}
      <Button onClick={() => showModal()}>Upload to Server</Button>
      <Modal
        title="Submit"
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          type="password"
          placeholder="Enter password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </Modal>
    </>
  );
};

export default UploadDataForm;
