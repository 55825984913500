import { useState } from 'react';
import { Input, Modal, Button, notification } from 'antd';
import { parseAttendanceSummary } from '../utils/attendance';

const SubmitAttendanceForm = ({ data }: {
    data: string
}) => {
    const [password, setPassword] = useState('')

    const [modalOpen, setModalOpen] = useState(false)

    const [notificationApi, notificationContext] = notification.useNotification();

    const showModal = () => {
        setModalOpen(true);
    };

    const handleOk = async () => {
        console.log(`submitting: ${data}`)

        const summary = parseAttendanceSummary(data)

        if (summary === null) {
            notificationApi.error({
                message: 'Error',
                description: 'Error parsing attendance summary',
                placement: 'topRight'
            })
            setModalOpen(false)
            return
        }

        if (summary.key === '') {
            notificationApi.error({
                message: 'Error',
                description: 'Error parsing attendance summary. It must have a key! Make sure label is in the form "FMG <label>"',
                placement: 'topRight'
            })
            setModalOpen(false)
            return
        }

        try {
            const updateRes = await fetch('https://crm.natlabs.app/.netlify/functions/submit-attendance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': password
                },
                body: JSON.stringify(summary)
            })

            if (!updateRes.ok) {
                throw new Error(`Error submitting attendance dashboard ${updateRes.status} ${updateRes.statusText}`)
            }

            notificationApi.success({
                message: 'Success',
                description: 'Successfully submitted attendance dashboard',
                placement: 'topRight'
            })
            setModalOpen(false)
        } catch (err) {
            notificationApi.error({
                message: 'Error',
                description: `Error submitting attendance dashboard ${err}`,
                placement: 'topRight'
            })
            setModalOpen(false)
            return
        }
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    return <>
        {notificationContext}
        <Button onClick={() => showModal()}>Submit</Button>
        <Modal title="Submit" open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input type='password' placeholder='Enter password' onChange={(e) => setPassword(e.target.value)} value={password} />
        </Modal>
    </>
}

export default SubmitAttendanceForm;