import { useState } from "react";
import { Input, Modal, Button, notification } from "antd";

const LoadDataForm = ({
  dataKey,
  setFn,
}: {
  dataKey: string;
  setFn: (data: any) => void;
}) => {
  const [password, setPassword] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const [notificationApi, notificationContext] = notification.useNotification();

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = async () => {
    try {
      const dataRes = await fetch(
        `https://crm.natlabs.app/.netlify/functions/fetch-data?key=${dataKey}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: password,
          },
        }
      );

      const jsonRes = await dataRes.json();

      console.log(`Fetched results: ${JSON.stringify(jsonRes)}`);

      setFn(jsonRes.data);
      notificationApi.success({
        message: "Success",
        description: "Successfully loaded data",
        placement: "topRight",
      });
      setModalOpen(false);
    } catch (err) {
      notificationApi.error({
        message: "Error",
        description: `Error loading data ${err}`,
        placement: "topRight",
      });
      setModalOpen(false);
      return;
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      {notificationContext}
      <Button onClick={() => showModal()}>Load from Server</Button>
      <Modal
        title="Submit"
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          type="password"
          placeholder="Enter password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </Modal>
    </>
  );
};

export default LoadDataForm;
